import React from "react";

export default function BugBounty() {
    return (
        <main>
            <div class="container page mb-5">

                <h1>
                    <strong>Mobile Bug Bounty Program</strong>
                </h1>
                <p>
                    <strong>1. About the Program</strong>
                </p>
                <p>
                    Mobile, the largest mini-app on Telegram for selling eSIMs, invites security
                    specialists to participate in our Bug Bounty program. The goal of the
                    program is to identify and address potential vulnerabilities to protect data
                    and ensure the security of our clients.
                </p>
                <p>
                    <strong>2. Participation Rules</strong>
                </p>
                <ul>
                    <li>
                        Participants must comply with all requirements and rules of the Telegram
                        platform and applicable laws.
                    </li>
                    <li>
                        Any attempts to gain unauthorized access to user data, as well as
                        destructive testing (e.g., DDoS attacks), are prohibited.
                    </li>
                    <li>
                        Participants are required to report only those vulnerabilities that
                        affect the Mobile platform without disrupting the service.
                    </li>
                </ul>
                <p>
                    <strong>3. Vulnerability Categories</strong>
                </p>
                <p>
                    We accept reports on the following types of vulnerabilities:
                </p>
                <ul>
                    <li>
                        <strong>Authentication and Authorization Vulnerabilities:</strong>
                        vulnerabilities that allow access to other usersâ€™ accounts or data.
                    </li>
                    <li>
                        <strong>Financial Impact Vulnerabilities:</strong> any bugs that may
                        lead to financial losses, including bypassing the payment system or
                        improper use of internal balances and bonus systems.
                    </li>
                    <li>
                        <strong>Data Security Vulnerabilities:</strong> issues that could lead
                        to the leakage of users' personal information.
                    </li>
                    <li>
                        <strong>Vulnerabilities in Partner Platform Integrations:</strong>
                        potential issues that may affect functionality or data security when
                        interacting with partner services.
                    </li>
                </ul>
                <p>
                    <strong>4. Reward Levels</strong>
                </p>
                <ul>
                    <li>
                        <strong>Critical Vulnerabilities</strong> (e.g., full access to user
                        data, bypassing payment systems): up to $1000.
                    </li>
                    <li>
                        <strong>High Risk</strong> (e.g., access to private data, manipulation
                        of internal balances): up to $500.
                    </li>
                    <li>
                        <strong>Medium Risk</strong> (e.g., vulnerabilities affecting app
                        functionality): up to $250.
                    </li>
                    <li>
                        <strong>Low Risk</strong> (e.g., less critical interface issues): up to
                        $100.
                    </li>
                </ul>
                <p>
                    All rewards will be paid after our team confirms the vulnerability.
                </p>
                <p>
                    <strong>5. How to Submit a Report</strong>
                </p>
                <ul>
                    <li>
                        Describe the vulnerability in as much detail as possible, including
                        steps to reproduce it.
                    </li>
                    <li>
                        Specify the impact of the vulnerability and potential risks.
                    </li>
                    <li>
                        Attach screenshots, videos, or other materials that will help our team
                        understand and reproduce the issue.
                    </li>
                    <li>
                        Submit your report via <a href="mailto:pa@mobile.tg">pa@mobile.tg</a> or <a
                        href="https://t.me/MobileSuppBot" target="_blank">@MobileSuppBot</a>.
                    </li>
                </ul>
                <p>
                    <strong>6. Additional Terms</strong>
                </p>
                <ul>
                    <li>
                        We reserve the right to determine the validity of vulnerabilities and
                        the eligibility for rewards.
                    </li>
                    <li>
                        Participants who identify vulnerabilities and follow the rules will not
                        face legal actions if they act within the programâ€™s guidelines.
                    </li>
                    <li>
                        Mobile reserves the right to change the terms of the program at any
                        time.
                    </li>
                </ul>
                <p>
                    <strong>7. Exclusions</strong>
                </p>
                <p>
                    The Bug Bounty program does not cover:
                </p>
                <ul>
                    <li>
                        UI/UX issues without significant impact on security.
                    </li>
                    <li>
                        Vulnerabilities found on third-party platforms that are not integrated
                        with Mobile.
                    </li>
                    <li>
                        Functionality that does not affect data security or integrity.
                    </li>
                </ul>
                <p>
                    <strong>8. Contact</strong>
                </p>
                <p>
                    If you have questions about the program or the report submission process,
                    please contact us via email at <a href="mailto:pa@mobile.tg">pa@mobile.tg</a>
                    .
                </p>
            </div>
        </main>
    )
}
