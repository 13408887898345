import {useContext, useEffect, useState} from "react";
import {
    CreateOrder,
    FetchCountries,
    FetchGroups,
    FetchTariffs,
    CheckWebAuth,
    GetBundles, GetCountries,
    PayOrder
} from "../../common/Api";
import {LocalizationContext} from "../../common/Localization";
import {useHash} from "../../components/hash";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import BundlesGroup from "../../esim/BundlesGroup";
import searchIcon from "../../assets/search.svg";
import resetIcon from "../../assets/reset.svg";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import EsimMain from "./esimMain";
import Faq from "../faq";
import Checkout from "./checkout";

function Esim() {
    const localization = useContext(LocalizationContext);
    const [bundles, setBundles] = useState(null);
    const [groups, setGroups] = useState(null);
    const [ready, setReady] = useState(false);
    const [countries, setCountries] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemTariffs, setItemTariffs] = useState([]);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [hash, setHash] = useHash();
    const authorize = async () => {
        if (!authToken) {
            let tokenResponse = await CheckWebAuth();
            if (tokenResponse.status === 200) {
                let tokenJson = await tokenResponse.json();
                let token = tokenJson.response.session;
                localStorage.setItem("authToken", token);
                setAuthToken(token);
            }
        }
    }

    useEffect(() => {
        if (selectedItem) {
            let ret = bundles.filter(t =>
                (!selectedItem.group || t.group_id === selectedItem.group.id) &&
                (!selectedItem.country || t.country_id === selectedItem.country.id) &&
                t.has_simcard
            );
            setPaymentUrl(null)
            setItemTariffs(ret);
            setSelectedTariff(ret[0]);
        } else {
            setItemTariffs([]);
            setSelectedTariff(null);
        }

    }, [selectedItem, bundles]);

    useEffect(() => {
        if (ready) {
            if (hash.startsWith("#g")) {
                let group = groups.find(g => g.id === parseInt(hash.substring(2)));
                setSelectedItem({
                    group: group,
                    title: localization.GetString("group.name." + group.name),
                    image: "/img/" + group.name + ".png",
                    description: localization.GetString("group.countries." + group.name)
                });
            } else if (hash.startsWith("#c")) {
                let country = countries.find(c => c.id === parseInt(hash.substring(2)));
                setSelectedItem({
                    country: country,
                    title: localization.GetString("country.name." + country.name),
                    image: "/img/" + country.name + ".png"
                });
            } else {
                setSelectedItem(null);
            }
            global.scrollTo({top: 0, left: 0, behavior: "smooth"});

        }
    }, [ready, hash])


    const tariffName = (tariff) => {

        let name = tariff.name;
        let parts = name.split("_");
        let gb = parts[0].replace("gb", "");
        let days = parts[1].replace("days", "");
        return gb + "GB " + localization.GetString("for") + " " + days + " " + localization.GetString("days");
    }

    useEffect(() => {

        async function checkAuth() {
            /*     let authToken = localStorage.getItem("authToken");
                 if (authToken) {
                     setAuthToken(authToken);
                 }

             */
        }

        async function getBundles() {
            const tariffs = await GetBundles();
            setBundles(tariffs);
        }


        async function getCountries() {
            const countries = await GetCountries();
            setCountries(countries);
        }

        Promise.all([checkAuth(), getBundles(), getCountries()]).then(() => {
            setReady(true);
        });
    }, []);


    return (
        <Routes>
            <Route path={"/"} element={<EsimMain mode={"country"}/>}/>
            <Route path={"/regions"} element={<EsimMain mode={"region"}/>}/>
            <Route path={"/search/:searchText"} element={<EsimMain mode={"search"}/>}/>
            <Route path={"/bundles/:id"} element={<EsimMain mode={"bundle"}/>}/>
            <Route path={"/checkout/:id"} element={<Checkout/>}/>
        </Routes>
    );
}

export default Esim;
