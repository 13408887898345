import React from 'react';

export default function Main() {
    return (
            <main>
                <section className="section-1">
                    <div className="bg-1"><img src="/skin/img/bg-header.svg" alt=""/></div>
                    <div className="bg-2"><img src="/skin/img/bg-header2.svg" alt=""/></div>
                    <div className="container">
                        <h1>Speedy <b>Mobile Internet</b> for home & travel with <a
                            href="https://t.me/Mobile" className="mobile">Mobile</a> </h1>
                        <a href="https://t.me/Mobile" className="btn btn-secondary">
                            Get started <img src="/skin/img/icons/out.svg" alt=""/>
                        </a>
                    </div>
                </section>

                <section className="section-2" id="benefits">
                    <div className="container">
                        <div className="bg-top"><img src="/skin/img/bg-why.svg" alt=""/></div>
                        <div className="d-flex justify-content-center">
                            <div className="scroll-to-explore d-flex align-items-center"><img
                                src="/skin/img/icons/scroll.svg" alt="" className="mr-16px"/>Scroll to explore
                            </div>
                        </div>
                        <h1>Why Mobile<b>.</b></h1>
                        <div className="cards">
                            <div className="card">
                                <img src="/skin/img/icons/why-icon1.svg" alt=""/>
                                Purchase your eSIM from anywhere, use it instantly
                            </div>
                            <div className="card">
                                <img src="/skin/img/icons/why-icon2.svg" alt=""/>
                                eSIM can be used alongside a regular SIM card of any cellular service provider
                            </div>
                            <div className="card">
                                <img src="/skin/img/icons/why-icon4.svg" alt=""/>
                                Pay with @Wallet, Bank card and TON
                            </div>
                            <div className="card">
                                <img src="/skin/img/icons/why-icon5.svg" alt=""/>
                                Stay connected in 150+ countries and regions around the world
                            </div>
                        </div>
                        <div className="bg-bottom"><img src="/skin/img/bg-why.svg" alt=""/></div>
                    </div>
                </section>

                <section className="section-3" id="howitworks">
                    <div className="container">
                        <div className="bg-1"><img src="/skin/img/bg-how.svg" alt=""/></div>
                        <div className="bg-2"><img src="/skin/img/bg-how2.svg" alt=""/></div>
                        <h1>How Mobile works<b>.</b></h1>
                        <div className="cards">
                            <div className="card">
                                <img src="/skin/img/icons/how-icon1.svg" alt=""/>
                                <span className="d-flex flex-row">Go to <a href="https://t.me/Mobile" target="_blank"
                                                                           className="mobile mobile-sm">@Mobile</a></span>
                            </div>
                            <div className="card">
                                <img src="/skin/img/icons/how-icon2.svg" alt=""/>
                                Choose your destination and package
                            </div>
                            <div className="card">
                                <img src="/skin/img/icons/how-icon3.svg" alt=""/>
                                Install and activate your eSIM
                            </div>
                            <div className="card">
                                <img src="/skin/img/icons/how-icon4.svg" alt=""/>
                                Track your balance here
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12 mb-5 mb-md-0 text-center text-md-left">
                                <div className="ready">
                                    <h1 className="text-nowrap text-md-left">Ready to try<br/>Mobile?</h1>
                                    <a href="https://t.me/Mobile" target="_blank" className="btn btn-secondary">Get
                                        started <img src="/skin/img/icons/out.svg" alt=""/></a>
                                </div>
                            </div>
                            <div
                                className="col-md-6 col-12 d-flex align-items-end justify-content-center justify-content-md-start">
                                <img src="/skin/img/cube.png" className="phone-bg" alt=""/>
                                <img src="/skin/img/phone.png" className="phone-img" alt=""/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-5">
                    <div className="container">
                        <img src="/skin/img/wave-1.svg" className="wave-1" alt=""/>
                        <img src="/skin/img/wave-2.svg" className="wave-2" alt=""/>
                        <img src="/skin/img/cube-2.png" className="bg-left" alt=""/>
                        <img src="/skin/img/cube-3.png" className="bg-right" alt=""/>
                        <h1>Any questions?</h1>
                        <p>If you have any other questions, do not hesitate to contact our support team</p>
                        <a href="https://t.me/MobileSuppBot" target="_blank" className="btn btn-white">@MobileSuppBot</a>
                    </div>
                </section>
            </main>
    );
}
