import React, {useContext} from "react";


export default function ListRow({imageUrl, label, value, onClick, imageHeight, image}) {


    return (
        <div onClick={onClick} className={"list-row"}>
            {image ? image : imageUrl ? (
                <div style={{backgroundImage: `url(${imageUrl})`}} className={"icon"}/>) : null}

            <div className={"label"}>{label}</div>
            {value && (<div className={"value"}>{value}</div>)}
        </div>
    );
}
