import React, {useContext, useEffect, useMemo, useState} from "react";

import BundleFlag from "./BundleFlag";

import {LocalizationContext} from "../common/Localization";
import ListRow from "./ListRow";
import {useNavigate} from "react-router-dom";

export default function BundlesList({bundles}) {
    const localization = useContext(LocalizationContext);
    const navigation = useNavigate();
    console.log(bundles)


    return ((bundles).map((bundle) => (
            <ListRow
                onClick={() => {
                    navigation("/esim/bundles/" + bundle.id);
                }}
                image={(<BundleFlag iconName={bundle.iconName}/>)}asd
                label={localization.GetLocalized(bundle.name)}
                key={bundle.id}/>
        ))
    );
}


