import React from "react";

export default function Agreement() {
    return (
        <main>
            <div className="container page mb-5">
                <h1>Terms and Conditions</h1>
                <p>
                    <strong>BACKGROUND:</strong>
                </p>
                <p>
                    These Terms and Conditions are the standard terms for the sale of e-SIM
                    services via our Telegram bot by TONAVE SERVICES LIMITED, trading under the
                    brand Mobile.
                </p>
                <p>
                    <strong>1. Definitions and Interpretation</strong>
                </p>
                <p>
                    1.1 In these Terms and Conditions, unless the context otherwise requires,
                    the following expressions have the following meanings:
                </p>
                <p>
                    <strong>Telegram Bot</strong> means our Telegram bot @Mobile;
                </p>
                <p>
                    <strong>Calendar Day</strong> means any day of the year;
                </p>
                <p>
                    <strong>Contract</strong> means the contract for the purchase and sale of
                    Services, as explained in Clause 3;
                </p>
                <p>
                    <strong>Order</strong> means your order for the Services, made via the
                    Telegram Bot;
                </p>
                <p>
                    <strong>Order Confirmation</strong> means Our acceptance and confirmation of
                    your Order in the form of a QR code for e-SIM sent as described in Clause 3;
                </p>
                <p>
                    <strong>Pre-Contract Information</strong> means these Terms and Conditions,
                    terms of use of various eSIM service providers, information about TONAVE
                    SERVICES LIMITED, the Services, pricing, and all information that will be
                    made available to you via the Telegram Bot;
                </p>
                <p>
                    <strong>Price</strong> means the price payable for the Services and
                    available in the Telegram Bot;
                </p>
                <p>
                    <strong>Services</strong> means the services on sale of third-party embedded
                    SIM cards (e-SIM) as specified in your Order (and confirmed in Our Order
                    Confirmation);
                </p>
                <p>
                    <strong>Special Price</strong> means a special offer price payable for the
                    Services;
                </p>
                <p>
                    <strong>We/Us/Our</strong> means TONAVE SERVICES LIMITED, trading under the
                    brand name Mobile, a company registered in the Republic of Cyprus under
                    number HE435321, whose registered address is at Souliou, 18 Strovolos, 2018,
                    Nicosia, Cyprus.
                </p>
                <p>
                    1.2 Each reference in these Terms and Conditions to “writing” and any
                    similar expression includes electronic communications whether sent by email,
                    text message, or other means.
                </p>
                <p>
                    <strong>2. Information About Us</strong>
                </p>
                <p>
                    2.1 Tonave Services Limited, trading under the brand name Mobile, is a
                    limited company registered in the Republic of Cyprus under number HE435321,
                    whose registered address is at Souliou, 18 Strovolos, 2018, Nicosia, Cyprus.
                </p>
                <p>
                    2.2 We are a reseller of eSIM services provided by various network operators
                    and service providers.
                </p>
                <p>
                    2.3 We ARE NOT a mobile provider or mobile network operator. Data services
                    are provided by their respective network providers in their country.
                    Although we are not the network provider, we will provide support to
                    end-users who purchase services we resell.
                </p>
                <p>
                    2.4 WE DO NOT ASSUME ANY RESPONSIBILITY AND MAY NOT BE HELD RESPONSIBLE OR
                    LIABLE FOR CONNECTIVITY AS SUCH, ITS QUALITY, QUANTITY, DIVERSITY, COVERAGE,
                    SECURITY, OR ANY OTHER FEATURE OR OPTION A CONSUMER MAY EXPECT FROM A MOBILE
                    OPERATOR OR PROVIDER.
                </p>
                <p>
                    2.5 By accepting these Terms and Conditions, you expressly acknowledge that
                    you have read, understood, consented, and agreed to the terms of use of the
                    respective eSIM service providers. Those terms and conditions may be amended
                    from time to time, and you agree to accept all such amendments as well as
                    agree that it is your sole responsibility to review and get yourself
                    familiar with all such amendments.
                </p>
                <p>
                    2.6 We provide our services via our Telegram bot @Mobile. By accepting these
                    Terms and Conditions, you agree to use the Telegram bot as the primary means
                    of communication. IF YOU DISAGREE WITH OUR TERMS OF USE OR ANY OTHER POLICY,
                    PLEASE DO NOT USE OUR SERVICES.
                </p>
                <p>
                    <strong>3. The Contract</strong>
                </p>
                <p>
                    3.1 These Terms and Conditions govern the sale of Services by Us, via the
                    Telegram Bot, and will form the basis of the Contract between Us and you.
                    Before submitting your Order, you should ensure that you have read these
                    Terms and Conditions and the Pre-Contract Information carefully.
                </p>
                <p>
                    3.2 Nothing provided by Us including, but not limited to, information given
                    via the Telegram Bot, over the telephone, or in messages, sales and
                    marketing literature, price lists, and other information constitutes a
                    contractual offer capable of acceptance. Your Order constitutes a
                    contractual offer that We may, at our discretion, accept.
                </p>
                <p>
                    3.3 A legally binding Contract between Us and you will be created upon Our
                    acceptance of your Order (Order Confirmation). Order Confirmations will be
                    provided in writing via the Telegram Bot.
                </p>
                <p>
                    3.4 IT IS YOUR RESPONSIBILITY TO MAKE SURE OUR SERVICES ARE COMPATIBLE WITH
                    YOUR DEVICE AND THE LAWS AND REGULATIONS OF THE COUNTRY YOU INTEND TO USE
                    OUR SERVICES IN. We use our best efforts to make a list of devices that are
                    compatible with the Services and are happy to provide you with consultation
                    based on third-party information on compatibility. However, in no event do
                    we assume any responsibility for its accuracy.
                </p>
                <p>
                    <strong>4. Orders</strong>
                </p>
                <p>
                    4.1 All Orders for Services made by you via the Telegram Bot will be subject
                    to these Terms and Conditions.
                </p>
                <p>
                    4.2 Unless otherwise indicated in this Clause 4, you may not cancel your
                    Order once you have received the Order Confirmation.
                </p>
                <p>
                    4.3 If you change your mind, you may cancel your Order in certain cases.
                    Please refer to Clause 11 for details of your cancellation options.
                </p>
                <p>
                    4.4 We may cancel your Order at any time in the following circumstances:
                </p>
                <p>
                    4.4.1 The required materials (e-SIM) necessary for the provision of the
                    Services are not available; or
                </p>
                <p>
                    4.4.2 An event outside of Our control continues for more than 15 calendar
                    days (please see Clause 10 for events outside of Our control).
                </p>
                <p>
                    4.5 If We cancel your Order under sub-Clause 4.4 and you have already made
                    any payment to Us, the payment will be refunded to you within 14 calendar
                    days. If We cancel your Order, the cancellation will be confirmed in writing
                    via the Telegram Bot.
                </p>
                <p>
                    4.6 We reserve the right to limit the sales of Services to any person,
                    geographic region, or jurisdiction. We may exercise this right on a
                    case-by-case basis. We reserve the right to limit the quantity of Services
                    that we offer.
                </p>
                <p>
                    <strong>5. Price and Payment</strong>
                </p>
                <p>
                    5.1 The Price of the Services will be available in the Telegram Bot.
                </p>
                <p>
                    5.2 If We offer a Special Price, the Special Price will be valid for a
                    period indicated in the Telegram Bot or, if the Special Price is part of an
                    advertised special offer, for the period shown in the advertisement. If the
                    Special Price requires a promotion or voucher code and you are unable to
                    provide a valid promotion or voucher code when making your Order, the
                    Special Price will not be available to you. Orders placed during the
                    validity period of a Special Price will be accepted at the Special Price
                    even if We do not accept your Order until after the period has expired.
                </p>
                <p>
                    5.3 Our Prices may change at any time, but these changes will not affect any
                    Orders that We have already accepted.
                </p>
                <p>
                    5.4 All Prices include VAT. If the rate of VAT changes between the date of
                    your Order and the date of your payment, We will adjust the rate of VAT that
                    you must pay. Changes in VAT will not affect any Prices where We have
                    already received payment from you.
                </p>
                <p>
                    5.5 Pricing and payment structures (including due dates for payment) may
                    vary according to the nature of the Services ordered.
                </p>
                <p>
                    5.6 All and any bank fees and/or fees associated with payment methods listed
                    in sub-Clause 5.5 shall be borne by you.
                </p>
                <p>
                    5.7 If you do not make any payment to Us by the due date, we reserve our
                    right to cancel your Order without additional notice to you.
                </p>
                <p>
                    5.8 The provisions of sub-Clause 5.7 will not apply if you have promptly
                    contacted Us to dispute an invoice or payment for Services in good faith. No
                    interest will accrue while such a dispute is ongoing.
                </p>
                <p>
                    <strong>6. Providing the Services</strong>
                </p>
                <p>
                    6.1 We will provide the Services with reasonable skill and care, consistent
                    with best practices and standards in the industry, and in accordance with
                    any information provided by Us about the Services and about Us.
                </p>
                <p>
                    6.2 We will make every reasonable effort to provide the Services in a timely
                    manner and to complete them on time. We cannot, however, be held responsible
                    for any delays if an event outside of Our reasonable control occurs. Please
                    see Clause 10 for events outside of Our control.
                </p>
                <p>
                    6.3 If We require any information from you in order to provide the Services,
                    We will inform you of this as soon as is reasonably possible.
                </p>
                <p>
                    6.4 If the information you provide under sub-Clause 6.3 is delayed,
                    incomplete, or otherwise incorrect, We will not be responsible for any delay
                    caused as a result.
                </p>
                <p>
                    <strong>7. Problems with the Services</strong>
                </p>
                <p>
                    7.1 We always use reasonable endeavours to ensure that Our Services are
                    trouble-free. If, however, there is a problem with the Services, We request
                    that you inform Us as soon as is reasonably possible via the Telegram Bot.
                </p>
                <p>
                    7.2 We will use reasonable efforts to remedy problems with the Services as
                    quickly as is reasonably possible and practical.
                </p>
                <p>
                    <strong>8. Prohibited use of Services</strong>
                </p>
                <p>
                    You MAY NOT use Services and e-SIMs:
                </p>
                <p>
                    (a) for any unlawful purpose; (b) to solicit others to perform or
                    participate in unlawful acts; (c) to violate any international, federal,
                    provincial, or state regulations, rules, or local ordinances; (d) to
                    infringe or violate our or any third party’s intellectual property rights;
                    (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate
                    or discriminate on the basis of gender, sexual orientation, religion,
                    ethnicity, race, age, national origin or disability; (f) to present false or
                    misleading information; (g) to upload or transmit viruses or any other
                    malicious code that is or may be used in any way that could compromise the
                    functionality or performance of the Service or any related website, other
                    sites, or the Internet; (h) to collect or track personal information about
                    others; (i) to generate spam, phish, pharm, pretext, spider, crawl, or
                    scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or
                    circumvent the security features of the Service or any related website,
                    other sites, or the Internet; (l) in violation of these Terms and Conditions
                    or the Pre-Contract Information. We reserve the right to suspend your use of
                    the Service or any related website for violating any of the prohibited use
                    items.
                </p>
                <p>
                    <strong>9. Our Liability</strong>
                </p>
                <p>
                    9.1 WE MAKE NO WARRANTY OR REPRESENTATION THAT THE SERVICES ARE FIT FOR
                    COMMERCIAL, BUSINESS OR INDUSTRIAL PURPOSES OF ANY KIND (INCLUDING RESALE),
                    ARE COMPLETE OR FREE OF ERRORS.
                </p>
                <p>
                    9.2 WE WILL NOT BE LIABLE TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS,
                    INTERRUPTION TO BUSINESS OR FOR ANY LOSS OF BUSINESS OPPORTUNITY.
                </p>
                <p>
                    9.3 WE HEREBY EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS,
                    IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
                    MERCHANTABILITY, FITNESS, SUITABILITY OR USABILITY FOR A PARTICULAR PURPOSE
                    OR PERIOD, AND NON-INFRINGEMENT WITH RESPECT TO ALL SERVICES, EQUIPMENT,
                    HARDWARE, SOFTWARE, OR OTHER PRODUCTS PROVIDED HEREUNDER, INCLUDING WITHOUT
                    LIMITATION THE MOBILE SERVICES, CONNECTIVITY, SECURITY, COVERAGE, OR IMSI.
                </p>
                <p>
                    9.4 IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR USAGE OF THE
                    SERVICES. IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                    COMPATIBILITY OF THE SERVICES TO THE DEVICE THE SERVICES ARE, WILL, OR WERE
                    INTENDED TO BE USED ON.
                </p>
                <p>
                    <strong>10. Events Outside of Our Control (Force Majeure)</strong>
                </p>
                <p>
                    10.1 We will not be liable for any failure or delay in performing Our
                    obligations where that failure or delay results from any cause that is
                    beyond Our reasonable control. Such causes include, but are not limited to:
                    power failure, internet service provider failure, mobile coverage failure,
                    strikes, lock-outs, or other industrial action by third parties, riots and
                    other civil unrest, fire, explosion, flood, storms, earthquakes, subsidence,
                    acts of terrorism (threatened or actual), acts of war (declared, undeclared,
                    threatened, actual or preparations for war), epidemic or other natural
                    disaster, or any other event that is beyond Our reasonable control.
                </p>
                <p>
                    <strong>11. Cancellation and Refunds</strong>
                </p>
                <p>
                    11.1 You may cancel your Order via the Telegram Bot any time before you pay
                    the price for the Services.
                </p>
                <p>
                    11.2 As indicated in sub-Clause 4.4, the Order may be cancelled at any time
                    if the required materials (e-SIM) necessary for the provision of the
                    Services are not available or an event outside of Our control continues for
                    more than 15 calendar days.
                </p>
                <p>
                    11.3 Refunds under this Clause 11 will be issued to you no later than 14
                    Calendar Days after the date on which you inform Us that you wish to cancel.
                </p>
                <p>
                    11.4 Refunds under this Clause 11 will be made using the same payment method
                    you used when ordering the Services unless you specifically request that We
                    make a refund using a different method.
                </p>
                <p>
                    11.5 If you received the Order Confirmation, your right to cancel is lost
                    and, for the avoidance of doubt, you will not be entitled to any refund.
                </p>
                <p>
                    11.6 As indicated in sub-Clause 3.4, it is your responsibility to confirm
                    compatibility of your device with Our Services. If it is discovered that the
                    device is not compatible after the Order Confirmation is received, your
                    right to cancel is lost and you will not be entitled to any refund.
                </p>
                <p>
                    <strong>12. Communication and Contact Details</strong>
                </p>
                <p>
                    12.1 If you wish to contact Us with general questions or complaints, you may
                    contact Us via the Telegram bot
                    <a href="https://t.me/MobileSuppBot">
                        @MobileSuppBot
                    </a>
                    .
                </p>
                <p>
                    12.2 For orders, payments, and delivery please contact Us via the Telegram
                    Bot.
                </p>
                <p>
                    <strong>13. Complaints and Feedback</strong>
                </p>
                <p>
                    13.1 We always welcome feedback from Our customers and, whilst We always use
                    all reasonable endeavours to ensure that your experience as a customer of
                    Ours is a positive one, We nevertheless want to hear from you if you have
                    any cause for complaint.
                </p>
                <p>
                    13.2 All complaints are handled in accordance with Our complaints policy and
                    procedure, available on
                    <a href="https://mobile.tg/en/complaints">
                        https://mobile.tg/en/complaints
                    </a>
                    .
                </p>
                <p>
                    <strong>14. How We Use Your Personal Information (Data Protection)</strong>
                </p>
                <p>
                    We will only use your personal information as set out in Our Privacy Notice
                    available on
                    <a href="https://mobile.tg/en/privacy">
                        https://mobile.tg/en/privacy
                    </a>
                    .
                </p>
                <p>
                    <strong>15. Other Important Terms</strong>
                </p>
                <p>
                    15.1 We may transfer (assign) Our obligations and rights under these Terms
                    and Conditions (and under the Contract, as applicable) to a third party
                    (this may happen, for example, if We sell Our business). If this occurs, you
                    will be informed by Us in writing. Your rights under these Terms and
                    Conditions will not be affected and Our obligations under these Terms and
                    Conditions will be transferred to the third party who will remain bound by
                    them.
                </p>
                <p>
                    15.2 You may not transfer (assign) your obligations and rights under these
                    Terms and Conditions (and under the Contract, as applicable) without Our
                    express written permission.
                </p>
                <p>
                    15.3 The Contract is between you and Us. It is not intended to benefit any
                    other person or third party in any way and no such person or party will be
                    entitled to enforce any provision of these Terms and Conditions.
                </p>
                <p>
                    15.4 If any of the provisions of these Terms and Conditions are found to be
                    unlawful, invalid, or otherwise unenforceable by any court or other
                    authority, that/those provision(s) shall be deemed severed from the
                    remainder of these Terms and Conditions. The remainder of these Terms and
                    Conditions shall be valid and enforceable.
                </p>
                <p>
                    15.5 No failure or delay by Us in exercising any of Our rights under these
                    Terms and Conditions means that We have waived that right, and no waiver by
                    Us of a breach of any provision of these Terms and Conditions means that We
                    will waive any subsequent breach of the same or any other provision.
                </p>
                <p>
                    <strong>16. Governing Law and Jurisdiction</strong>
                </p>
                <p>
                    16.1 These Terms and Conditions, the Contract, and the relationship between
                    you and Us (whether contractual or otherwise) shall be governed by, and
                    construed in accordance with, the law of the Republic of Cyprus.
                </p>
                <p>
                    16.2 Any dispute, controversy, proceedings, or claim between you and Us
                    relating to these Terms and Conditions, the Contract, or the relationship
                    between you and Us (whether contractual or otherwise) shall be subject to
                    the jurisdiction of the courts of the Republic of Cyprus.
                </p>
                <p>
                    <strong>Additional Information:</strong>
                </p>
                <p>
                    Till Deal Ltd, with its registered address at 105-109 Sumatra Road, London,
                    England, NW6 1PL is the authorized reseller and merchant of the products and
                    services offered within this store on the territory of EU, UK and ROW.
                </p></div>
        </main>
    );
}
