import React from 'react';

export default function Privacy() {
    return (<main>
        <div className="container page mb-5">
            <h1>Privacy Notice</h1>
            <p>
                <strong>BACKGROUND:</strong>
            </p>
            <p>
                <strong>TONAVE SERVICES LIMITED</strong> understands that your privacy is
                important to you and that you care about how your personal data is used. We
                respect and value the privacy of all our clients, customers, and partners,
                and will only collect and use personal data in ways that are described here,
                and in a way that is consistent with our obligations and your rights under
                the law.
            </p>
            <p>
                <strong>1. Information About Us</strong>
            </p>
            <p>
                1.1 Tonave Services Limited, trading under the brand name Mobile, is a
                limited company registered in the Republic of Cyprus under number HE 435321,
                with a registered address at Souliou, 18 Strovolos, 2018, Nicosia, Cyprus.
            </p>
            <p>
                1.2 We ARE NOT a mobile provider or mobile network operator. Data services
                are provided by their respective network providers in their country.
                Although we are not the network provider, we will provide support to
                end-users who purchase services we resell.
            </p>
            <p>
                1.4 WE DO NOT ASSUME ANY RESPONSIBILITY AND MAY NOT BE HELD RESPONSIBLE OR
                LIABLE FOR CONNECTIVITY AS SUCH, ITS QUALITY, QUANTITY, DIVERSITY, COVERAGE,
                SECURITY, OR ANY OTHER FEATURE OR OPTION A CONSUMER MAY EXPECT FROM A MOBILE
                OPERATOR OR PROVIDER.
            </p>
            <p>
                1.5 We provide our services exclusively via our Telegram bot @Mobile. Please
                note that our services operate according to their own privacy policy. If you
                disagree with our privacy policy, terms of use, or any other policy, please
                do not use our services. For further details on managing your privacy
                settings, please refer to our privacy policy.
            </p>
            <p>
                1.6 Contact details:
            </p>
            <p>
                Email: <a href="mailto:support@mobile.tg">support@mobile.tg</a>
            </p>
            <p>
                <strong>2. What Does This Notice Cover?</strong>
            </p>
            <p>
                This Privacy Notice explains how we use your personal data: how it is
                collected, how it is held, and how it is processed. It also explains your
                rights under the law relating to your personal data.
            </p>
            <p>
                <strong>3. What Is Personal Data?</strong>
            </p>
            <p>
                Personal data is defined by the General Data Protection Regulation (EU
                Regulation 2016/679) (the “GDPR” or “the Data Protection Legislation”) as
                ‘any information relating to an identifiable person who can be directly or
                indirectly identified in particular by reference to an identifier’. Personal
                data is, in simpler terms, any information about you that enables you to be
                identified. Personal data covers obvious information such as your name and
                contact details, but it also covers less obvious information such as
                identification numbers, electronic location data, and other online
                identifiers.
            </p>
            <p>
                <strong>4. What Personal Data Do You Collect and How?</strong>
            </p>
            <p>
                We may collect and hold some or all of the following personal data:
            </p>
            <ul>
                <li>
                    Email address - Directly from you via email.
                </li>
                <li>
                    Business information including business name, business address, contact
                    details - Directly from you via email.
                </li>
                <li>
                    Payment information including card details, account number, bank name,
                    and address - Handled by third-party payment processors. We do not
                    directly collect or store this information.
                </li>
                <li>
                    Profile public information including Telegram user name, screen name and
                    picture(s) - Collected via Telegram bot API.
                </li>
            </ul>
            <p>
                <strong>5. How Do You Use My Personal Data?</strong>
            </p>
            <p>
                We may use your personal data for the following purposes:
            </p>
            <ul>
                <li>
                    Administering our business;
                </li>
                <li>
                    Supplying our services and support to you;
                </li>
                <li>
                    Managing payments for our services;
                </li>
                <li>
                    Personalising and tailoring our services for you;
                </li>
                <li>
                    Communicating with you and supplying you with information on our
                    services.
                </li>
            </ul>
            <p>
                With your permission and/or where permitted by law, we may also use your
                personal data for marketing purposes, which may include contacting you by
                email and text message with information, news, and offers on our services.
                You will not be sent any unlawful marketing or spam. We will always work to
                fully protect your rights and you will always have the opportunity to
                opt-out.
            </p>
            <p>
                We use the following automated system for carrying out certain kinds of
                decision-making. If at any point you wish to query any action that we take
                on the basis of this or wish to request ‘human intervention’ (i.e., have
                someone review the action themselves, rather than relying only on the
                automated method), please contact us to find out more using the details in
                Part 10.
            </p>
            <p>
                We will only use your personal data for the purpose(s) for which it was
                originally collected unless we reasonably believe that another purpose is
                compatible with that or those original purpose(s) and need to use your
                personal data for that purpose.
            </p>
            <p>
                In some circumstances, where permitted or required by law, we may process
                your personal data without your knowledge or consent. This will only be done
                within the bounds of the Data Protection Legislation and your legal rights.
            </p>
            <p>
                <strong>6. How Long Will You Keep My Personal Data?</strong>
            </p>
            <p>
                We will not keep your personal data for any longer than is necessary in
                light of the reason(s) for which it was first collected. We will keep your
                personal data to comply with our legal obligations, resolve disputes, and
                enforce our agreements unless a longer retention period is required or
                permitted by law.
            </p>
            <p>
                <strong>7. How and Where Do You Store or Transfer My Personal Data?</strong>
            </p>
            <p>
                Generally, we store your personal data electronically in an EU member state.
                However, we may store or transfer some or all of your personal data in
                countries that are not part of the European Economic Area (the “EEA”
                consists of all EU member states, plus Norway, Iceland, and Liechtenstein).
                These are known as “third countries” and may not have data protection laws
                that are as strong as those in the EEA. This means that we will take
                additional steps in order to ensure that your personal data is treated just
                as safely and securely as it would be within the EEA. By agreeing to use our
                services, you expressly agree and consent to us transferring your personal
                data and storing it in the aforementioned countries.
            </p>
            <p>
                <strong>8. Do You Share My Personal Data?</strong>
            </p>
            <p>
                We will not share any of your personal data with any third parties for any
                purposes.
            </p>
            <p>
                We may share your details with a credit card processor, which you will be
                notified about separately.
            </p>
            <p>
                If any of your personal data is shared with a third party as described
                above, we will take steps to ensure that your personal data is handled
                safely, securely, and in accordance with your rights.
            </p>
            <p>
                If any personal data is transferred outside of the EEA, we will take
                suitable steps to ensure that your personal data is treated just as safely
                and securely as it would be within the EEA.
            </p>
            <p>
                <strong>9. How Can I Access My Personal Data?</strong>
            </p>
            <p>
                If you want to know what personal data we have about you, you can ask us for
                details of that personal data and for a copy of it (where any such personal
                data is held).
            </p>
            <p>
                All subject access requests should be made in writing and sent to the email
                in Part 10.
            </p>
            <p>
                There is not normally any charge for a subject access request. If your
                request is ‘manifestly unfounded or excessive’ (for example, if you make
                repetitive requests), a fee may be charged to cover our administrative costs
                in responding.
            </p>
            <p>
                We will do our best to respond to your subject access request within 20
                calendar days and, in any case, not more than one month of receiving it.
                Normally, we aim to provide a complete response, including a copy of your
                personal data within that time. In some cases, however, particularly if your
                request is more complex, more time may be required, up to a maximum of three
                months from the date we receive your request. You will be kept fully
                informed of our progress.
            </p>
            <p>
                <strong>10. How Do I Contact You?</strong>
            </p>
            <p>
                To contact us about anything to do with your personal data and data
                protection, including to make a subject access request, please use the
                following details:
            </p>
            <p>
                Telegram bot: <a href="https://t.me/MobileSuppBot">@MobileSuppBot</a>
            </p>
            <p>
                <strong>11. Changes to this Privacy Notice</strong>
            </p>
            <p>
                We may change this Privacy Notice from time to time. This may be necessary,
                for example, if the law changes, or if we change our business in a way that
                affects personal data protection.
            </p>
            <p>
                Any changes will be made available on
                <a href="https://mobile.tg/en/privacy">
                    https://mobile.tg/en/privacy
                </a>
                .
            </p>
            <p>
                This Privacy Policy was last updated on 12 June 2024.
            </p>
        </div>
    </main>);
}
