import React, {useEffect, useState} from "react";
import {CheckWebAuth, CreateOrder, GetOrder, OrderStatus} from "../../common/Api";
import {useNavigate, useParams} from "react-router-dom";
import ProgressLogo from "../../assets/progress-logo.svg";
import {LoadingSpinner} from "../../components/LoadingSpinner";

export default function Checkout() {
    const [orderData, setOrderData] = useState(null);
    const [order, setOrder] = useState(null);
    const [orderStatus, setOrderStatus] = useState("created");
    const {id} = useParams();
    const navigation = useNavigate();


    useEffect(() => {

        if (orderData == null || orderStatus === "completed" || orderStatus === "failed" || orderStatus === "paymentFailed") {
            return;
        }
        const interval = setInterval(async () => {
            const status = await OrderStatus(orderData.id);
            if (status.status !== orderStatus) {
                setOrderStatus(status.status);
                const order = await GetOrder(orderData.id);
                if (status.status === "completed") {
                    navigation("/esim/orders/" + order.id)
                } else if (status.status === "failed") {
                    navigation("/esim/faled/" + order.id);
                } else {
                    setOrder(order);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [id, orderStatus, order, orderData]);


    useEffect(() => {
        if (!id) {
            return;
        }
        CheckWebAuth(true).then((authorized) => {
            if (authorized) {
                CreateOrder(id).then((data) => {
                    setOrderData(data);
                });
            }

        });
    }, [id]);

    return (<>
        {(order == null || order.status === "created") ? orderData ? (

            <iframe src={orderData.url} className={"payframe"} title="payframe"></iframe>) : (<LoadingSpinner/>) : (
            <>
                <img src={ProgressLogo}/>
                {order.status === "paid" ? (<>
                    <h2>Payment successful</h2>
                    <p>Your payment was successful. Activating tariff now.</p>
                </>) : order.status === "paymentFailed" ? (<>
                    <h2>Payment failed</h2>
                    <p>Your payment was not successful. Please try again.</p>
                </>) : (<>
                    <h2>Something went wrong</h2>
                    <p>Please try again. If payment was made contact support.</p>
                </>)}

            </>)}</>);
}



