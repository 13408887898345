export async function GetBundles() {
    return Call('bundles').then(response => response.json());
}

export async function GetCountries() {
    return Call('countries').then(response => response.json());
}


export function CheckWebAuth(force = false) {
    return Call('user/webauth?force=' + force, {}).then(response => response.json());
    ;
}

export function CreateOrder(tariffId) {
    return Call('/orders/web/' + tariffId, {}).then(response => response.json());
}

export async function GetBundleTariffs(id) {
    return Call('tariff?bundleId=' + id).then(response => response.json());
}

export async function OrderStatus(id) {
    return Call('orders/' + id + '/status').then(response => response.json());
}
export async function GetOrder(id) {
    return Call('orders/' + id).then(response => response.json());
}

function Call(url, data = null) {
    let headers = {
        'Content-Type': 'application/json',

    };

    const baseUri = "/api/esim/"
    url = baseUri + url
    return fetch(url, {
        method: data ? 'POST' : 'GET',
        headers: headers,
        body: data == null ? null : new URLSearchParams(data)
    });
}
