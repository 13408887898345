import React, {useMemo} from "react";
import {useParams} from "react-router-dom";

const faq = require("../faq/pages.json")

export default function Faq() {
    const {id} = useParams();
    const page = useMemo(() => {
        if (!id) {
            return faq["d0da33504f33447ebe227a3a5bcf6cde"];
        }
        return faq[id];
    }, [id]);

    return (<div className={"container page faq-page"}>
        {
            page.path.length > 0 && (<div className={"faq-path"}>{
                page.path.map((path, index) => {
                    return <><a key={index} href={path.id}>{path.title}</a>> </>
                })}</div>)
        }
        <h1 className={"faq-title"}>{page.title}</h1>

        <div className={"faq-content"} dangerouslySetInnerHTML={{__html: page.content}}/>
    </div>);
}
