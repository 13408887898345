import loadingIcon from "../assets/loading.gif";
import React from "react";

export function LoadingSpinner() {
    return (
        <div className={"loader-wrap"}>
            <img style={{width: "40px"}} src={loadingIcon}/>
        </div>
    );
}
