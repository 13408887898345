import React from 'react';

const messages = {
    "select": {values: {"ru": "Выбрать", "en": "Select"}},
    "buy": {values: {"ru": "Купить", "en": "Buy"}},
    "for": {values: {"ru": "на", "en": "for"}},
    "days": {values: {"ru": "дней", "en": "days"}},
    "esim_info": {
        values: {
            "ru": "На этой eSIM будет только интернет-трафик.",
            "en": "This eSIM supports only data service."
        }
    },
    "pay": {values: {"ru": "Оплатить", "en": "Pay"}},
    "country.name.tr": {values: {"ru": "Турция", "en": "Turkey"}},
    "country.name.cy": {values: {"ru": "Кипр", "en": "Cyprus"}},
    "country.name.eg": {values: {"ru": "Египет", "en": "Egypt"}},
    "country.name.ae": {values: {"ru": "ОАЭ", "en": "UAE"}},
    "country.name.ru": {values: {"ru": "Россия", "en": "Russia"}},
    "country.name.il": {values: {"ru": "Израиль", "en": "Israel"}},
    "country.name.am": {values: {"ru": "Армения", "en": "Armenia"}},
    "country.name.ge": {values: {"ru": "Грузия", "en": "Georgia"}},
    "country.name.kz": {values: {"ru": "Казахстан", "en": "Kazakhstan"}},
    "country.name.uz": {values: {"ru": "Узбекистан", "en": "Uzbekistan"}},
    "group.name.euro": {values: {"ru": "Европа", en: "Europe"}},
    "group.name.asia": {values: {"ru": "Азия", en: "Asia"}},
    "group.name.latam": {values: {"ru": "Латинская Америка", "en": "Latin America"}},
    "group.countries.euro": {
        values: {
            "ru": "Андорра, Австрия, Бельгия, Болгария, Хорватия, Кипр, Чехия, Дания, Эстония, Финляндия, Франция, Германия, Гибралтар, Греция, Венгрия, Исландия, Ирландия, Италия, Латвия, Лихтенштейн, Литва, Люксембург, Мальта, Нидерланды, Норвегия , Польша, Португалия, Румыния, Словакия, Словения, Испания, Швеция, Швейцария, Турция, Великобритания.",
            "en": "Andorra, Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Gibraltar, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovak Republic, Slovenia, Spain, Sweden, Switzerland, Turkey, United Kingdom."
        }
    },
    "group.countries.asia": {
        values: {
            "ru": "Австралия, Вьетнам, Индонезия, Камбоджа, Китай, Лаосская Народно-Демократическая Республика, Макао, Малайзия, Сингапур, Тайвань, Таиланд, Южная Корея, Япония.",
            "en": "Australia, Vietnam, Indonesia, Cambodia, China, Lao People's Democratic Republic, Macau, Malaysia, Singapore, Taiwan, Thailand, South Korea, Japan."
        }
    },
    "group.countries.latam": {
        values: {
            "ru": "Аргентина, Боливия, Бразилия, Чили, Колумбия, Коста-Рика, Доминиканская Республика, Эквадор, Сальвадор, Гватемала, Гондурас, Мексика, Никарагуа, Панама, Парагвай, Перу, Венесуэла.",
            "en": "Argentina, Bolivia, Brazil, Chile, Colombia, Costa Rica, Dominican Republic, Ecuador, El Salvador, Guatemala, Honduras, Mexico, Nicaragua, Panama, Paraguay, Peru, Venezuela."
        }
    }

}

export const localization = {
    language: "en",

    SetLanguage: function (lang) {
        this.language = lang;
    },

    GetLocalized: function (localizedMessage) {
        if (!this.language || !localizedMessage.values[this.language]) {
            return "";
        }
        return localizedMessage.values[this.language];
    },

    GetString: function (key) {
        if (messages[key] == null) {
            return key;
        }
        return this.GetLocalized(messages[key]);
    }
}
export const LocalizationContext = React.createContext(localization);
