import React from "react";

import notFoundImage from "../assets/not_found.svg";
import zeroItemsImage from "../assets/zero_items.svg";

export default function NotFound({text, search = false}) {
    return (<div className={"not-found"}>
        <img src={search ? notFoundImage : zeroItemsImage}/>
        <p>{text}</p>
    </div>);
}
