import React, {useContext, useEffect, useMemo, useState} from "react";
import {

    CheckWebAuth, CreateOrder, GetBundles, GetBundleTariffs, GetCountries, OrderStatus, PayOrder
} from "../../common/Api";
import {LocalizationContext} from "../../common/Localization";
import {useHash} from "../../components/hash";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {BundleSelect} from "../../esim/BundleSelect";
import searchIcon from "../../assets/search.svg";
import resetIcon from "../../assets/reset.svg";
import BundlesGroup from "../../esim/BundlesGroup";
import BundlesList from "../../esim/BundlesList";
import NotFound from "../../esim/NotFound";
import BundleFlag from "../../esim/BundleFlag";
import {LoadingSpinner} from "../../components/LoadingSpinner";

function Esim({mode}) {
    const navigation = useNavigate();
    const location = useLocation();
    const localization = useContext(LocalizationContext);
    const [bundles, setBundles] = useState(null);
    const [ready, setReady] = useState(false);
    const [countries, setCountries] = useState(null);
    const [authorized, setAuthorized] = useState(null);
    const [found, setFound] = useState(null);
    const [tariffs, setTariffs] = useState(null);
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const authorize = async (force) => {
        let authResult = await CheckWebAuth(force);
        setAuthorized(authResult)
        return authResult;
    }
    const {id, searchText} = useParams();


    useEffect(() => {
        async function getBundles() {
            const tariffs = await GetBundles();
            setBundles(tariffs);
        }

        async function getCountries() {
            const countries = await GetCountries();
            setCountries(countries);
        }

        Promise.all([getBundles(), getCountries(), authorize(false)]).then(() => {
            setReady(true);
        });
    }, []);

    useEffect(() => {

        console.log("navigate", location);
    }, [location]);

    useEffect(() => {
        if (!countries || !bundles) {
            return;
        }

        if (!searchText) {
            setFound(null);
            return;
        }

        const resultCountries = countries.filter(country => country.searchNames.some(name => name.toLowerCase().includes(searchText.toLowerCase()))).map(country => country.code);
        const resultBundles = bundles.filter(bundle => bundle.countries.some(country => resultCountries.includes(country) || bundle.name.values.ru.toLowerCase().includes(searchText.toLowerCase()) || bundle.name.values.en.toLowerCase().includes(searchText.toLowerCase())));
        if (resultBundles.length === 0) {
            setFound({success: false});
            return;
        }
        setFound({
            success: true,
            country: resultBundles.filter(f => f.type === "country").sort((a, b) => b.popularity - a.popularity),
            region: resultBundles.filter(f => f.type === "region").sort((a, b) => b.popularity - a.popularity),
            global: resultBundles.filter(f => f.type === "global").sort((a, b) => b.popularity - a.popularity),
        });


    }, [searchText, countries, bundles]);

    useEffect(() => {
        if (!bundles || !id) {
            return;
        }
        setSelectedBundle(bundles.find(bundle => bundle.id === id));
        GetBundleTariffs(id).then((data) => {
            data = data.sort((a, b) => a.dataSize - b.dataSize);
            const minTariff = data[0];
            setSelectedTariff(minTariff);
            setTariffs(data);
        });
    }, [id, bundles]);




    return (<>
        <main>
            <div className="container page mb-5">
                <div id="page_index" className="router-view esim-content">
                    {!ready ? (<div id="loader">
                        <div className="loader"></div>
                    </div>) : mode === "bundle" ? selectedBundle && (

                        <>
                            <p className={"bundle-header"}><BundleFlag
                                iconName={selectedBundle.iconName}/> {localization.GetLocalized(selectedBundle.name)}
                            </p>
                            {tariffs?.length > 0 ? (<>
                                    <ul className={"tariffs-list"}>
                                        {tariffs.map((tariff) => (<li key={tariff.id}
                                                                      className={tariff.id == selectedTariff.id ? "selected" : ""}
                                                                      onClick={() => {
                                                                          setSelectedTariff(tariff);
                                                                      }}
                                        >
                                            <div className={"sizes"}>
                                                <div
                                                    className={"label"}>{tariff.dataSize} {localization.GetString("gb")}</div>
                                                <div
                                                    className={"value"}>{tariff.days} {localization.GetString("days")}</div>

                                            </div>
                                            <div>
                                                <div className={"label"}>{tariff.price} $</div>
                                                <div
                                                    className={"value"}>$ {tariff.gbPrice} / {localization.GetString("gb")} </div>
                                            </div>
                                            <div className={"checkwrap"}>
                                                {tariff.tariffDiscount > 0 && (<div className={"discount"}>
                                                    -{tariff.tariffDiscount}%
                                                </div>)}
                                                <div className={"check"}></div>
                                            </div>
                                        </li>))}
                                    </ul>
                                    <div className={"big-button"} onClick={async () => {
                                        navigation("/esim/checkout/" + selectedTariff.id);

                                    }}>Buy
                                    </div>
                                </>) : <LoadingSpinner/>}
                        </>) : (<>

                            <h1>Buy eSIM</h1>

                            <>
                                <p>Choose your destination and package</p>
                                <div className={"search"}>
                                    <div className={"input-wrap"}>
                                        <input type={"text"} value={searchText}
                                               placeholder={"Search"}
                                               onChange={(e) => {
                                                   if (e.target.value) {
                                                       navigation("/esim/search/" + e.target.value);
                                                   } else {
                                                       navigation("/esim");
                                                   }
                                               }}/>
                                        <img className={"search-icon"} src={searchIcon} alt=""/>
                                        {searchText && (<img className={"reset-icon"} src={resetIcon} onClick={() => {
                                            navigation("/esim");
                                        }}/>)}


                                    </div>
                                </div>
                                {found ? (<>
                                        {found.success ? (<>
                                            {found.country.length > 0 && (<> <p
                                                className={"pagetitle"}>{localization.GetString("Countries")}</p>
                                                <BundlesList
                                                    bundles={found.country}/></>)}
                                            {found.region.length > 0 && (<> <p
                                                className={"pagetitle"}>{localization.GetString("Regions")}</p>
                                                <BundlesList
                                                    bundles={found.region}/></>)}
                                            {found.global.length > 0 && (<>
                                                <h2>{localization.GetString("Global")}</h2><BundlesList
                                                bundles={found.global}/></>)}
                                        </>) : (<NotFound search={true}
                                                          text={localization.GetString("Not found")}/>)}
                                    </>) : (<Tabs onSelect={(index) => {
                                    if (index === 0) {

                                        navigation("/esim");
                                    } else {
                                        navigation("/esim/regions");
                                    }


                                }} selectedIndex={mode === "region" ? 1 : 0}
                                >
                                    <TabList>
                                        <Tab>Countries</Tab>
                                        <Tab>Regions</Tab>
                                    </TabList>
                                    <TabPanel>

                                        <p className={"pagetitle"}>Popular countries
                                        </p>
                                        <BundlesGroup type={"country"} showPopular={true}
                                                      allBundles={bundles}/>
                                        <p className={"pagetitle"}>All countries</p>
                                        <BundlesGroup type={"country"} alphabeticalSort={true}
                                                      allBundles={bundles}/>

                                    </TabPanel>
                                    <TabPanel>
                                        <p className={"pagetitle"}>Regions</p>
                                        <BundlesGroup type={"region"} allBundles={bundles}/>
                                    </TabPanel>
                                </Tabs>)}
                            </>
                        </>)}
                </div>
            </div>
        </main>
    </>);
}

export default Esim;
