import React from "react";
import config from "../config";

export default function BundleFlag({iconName}) {

    const getBundleImage = (iconName) => {
        if (iconName == null) {
            return config.imgUrl + "bundle-icons/default.svg";
        } else {
            return config.imgUrl + "bundle-icons/" + iconName + ".svg";
        }
    }
    return (
        <div className={"flag"} style={{
            backgroundImage: `url(${getBundleImage(iconName)})`
        }}/>
    );
}
