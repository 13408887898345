import React, {useContext, useMemo} from "react";
import {LoadingSpinner} from "../components/LoadingSpinner";
import BundlesList from "./BundlesList";
import {LocalizationContext} from "../common/Localization";

export default function BundlesGroup({allBundles,type, showPopular,alphabeticalSort = false}) {

    const localization = useContext(LocalizationContext);
    const bundles = useMemo(() => {
        if (allBundles == null) {
            return [];
        }
        return allBundles.filter(bundle => {
                if (showPopular && !bundle.popularity) {
                    return false
                }
                return bundle.type == type;
            }
        ).sort((a, b) => alphabeticalSort?(localization.GetLocalized(a.name).localeCompare(localization.GetLocalized(b.name))):(b.popularity - a.popularity));
    }, [allBundles]);

    return (
        <>
            {allBundles == null ? (<LoadingSpinner/>) : (<BundlesList bundles={bundles}/>)}
        </>
    );
}
